import { LiveSurveyReportDTO } from '../../swagger-generated-client-api/model/liveSurveyReportDTO';

export class LiveSurveyReports {
  protected constructor(readonly cycleCounts?: Map<string, number>, readonly uptimePercentages?: Map<string, number>) {}

  public static fromDTO(dto: LiveSurveyReportDTO): LiveSurveyReports {
    return new LiveSurveyReports(
      dto && dto.cycleCounts && new Map(Object.entries(dto.cycleCounts)),
      dto && dto.uptimePercentages && new Map(Object.entries(dto.uptimePercentages))
    );
  }
}
