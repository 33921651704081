import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LiveSurveyReports } from '../../models/data-models/live-survey-reports';
import {
  DownloadPathDTO,
  LiveSurveyControllerService,
  RunStateChartDTO
} from '../../swagger-generated-client-api';

@Injectable({ providedIn: 'root' })
export class LiveSurveyDataService {
  constructor(private liveSurveyControllerService: LiveSurveyControllerService) {}

  private readonly PLOTLY_DATE_FORMAT: string = 'YYYY-MM-DD HH:mm:ss.SS';

  private setStateDatapointTimestampsToLocal = (dataPoint: string[]) => {
    dataPoint[0] = moment
      .utc(dataPoint[0])
      .local()
      .format(this.PLOTLY_DATE_FORMAT);
    dataPoint[1] = moment
      .utc(dataPoint[1])
      .local()
      .format(this.PLOTLY_DATE_FORMAT);
    return dataPoint;
  };

  private setFilteredDatapointTimestampToLocal = (dataPoint: any) => {
    dataPoint[0] = moment
      .utc(dataPoint[0])
      .local()
      .format(this.PLOTLY_DATE_FORMAT);
    return dataPoint;
  };

  public getHistoricUpStateData$(surveyId: string, fromDate: Date, toDate: Date): Observable<RunStateChartDTO> {
    return this.liveSurveyControllerService.getLiveRunStateChart(surveyId, fromDate, toDate).pipe(
      map(liveRunStateChart => {
        liveRunStateChart.unknownStates = liveRunStateChart.unknownStates!.map(this.setStateDatapointTimestampsToLocal);
        liveRunStateChart.upStates = liveRunStateChart.upStates!.map(this.setStateDatapointTimestampsToLocal);
        liveRunStateChart.downStates = liveRunStateChart.downStates!.map(this.setStateDatapointTimestampsToLocal);
        liveRunStateChart.cycleStartingPoints = liveRunStateChart.cycleStartingPoints!.map(date =>
          moment
            .utc(date)
            .local()
            .format(this.PLOTLY_DATE_FORMAT)
        );
        liveRunStateChart.filteredStateChanges = liveRunStateChart.filteredStateChanges!.map(
          this.setFilteredDatapointTimestampToLocal
        );
        return liveRunStateChart;
      })
    );
  }

  public requestCsvDownloadLink$(
    surveyId: string,
    from: Date,
    to: Date,
    timeOffsetInMinutes: number
  ): Observable<DownloadPathDTO> {
    return this.liveSurveyControllerService.requestCsvDownloadLink(surveyId, from, to, timeOffsetInMinutes);
  }

  public requestAccelerationCsvDownloadLink$(
    surveyId: string,
    from: Date,
    to: Date,
    timeOffsetInMinutes: number
  ): Observable<DownloadPathDTO> {
    return this.liveSurveyControllerService.requestAccelerationCsvDownloadLink(surveyId, from, to, timeOffsetInMinutes);
  }

  public requestLiveRunSettingsJsonDownloadLink$(
    surveyId: string,
    predictionStartTimeUTC: Date
  ): Observable<DownloadPathDTO> {
    return this.liveSurveyControllerService.requestLiveRunSettingsJsonDownloadLink(surveyId, predictionStartTimeUTC);
  }

  public getSurveyLiveReports$(surveyIds: string[]): Observable<LiveSurveyReports> {
    return this.liveSurveyControllerService
      .getSurveyLiveReports(surveyIds)
      .pipe(map(liveSurveyReports => LiveSurveyReports.fromDTO(liveSurveyReports)));
  }
}
