import { DASHBOARD_ROUTES } from '../dashboard/dashboard-routing.config';

const BASE_PATH = DASHBOARD_ROUTES.SURVEYS.ABSOLUTE;

export const SURVEYS_ROUTES = {
  ROOT: {
    ABSOLUTE: [...BASE_PATH],
    RELATIVE: []
  },
  SURVEYS_OVERVIEW: {
    ABSOLUTE: [...BASE_PATH],
    RELATIVE: []
  },
  SURVEY_CREATE: {
    ABSOLUTE: [...BASE_PATH, 'create'],
    RELATIVE: ['create']
  },
  SURVEYS_DETAIL: {
    ABSOLUTE: (surveyId: string) => [...BASE_PATH, surveyId],
    RELATIVE: (surveyId: string) => [surveyId],
    PATH_KEYS: {
      SURVEY_ID: 'survey-id'
    }
  },
  SURVEYS_LIVE_RUN: {
    ABSOLUTE: (surveyId: string) => [...BASE_PATH, surveyId, 'live-run'],
    RELATIVE: (surveyId: string) => [surveyId, 'live-run'],
    PATH_KEYS: {
      SURVEY_ID: 'survey-id'
    }
  },
  SURVEYS_COCKPIT: {
    ABSOLUTE: (surveyId: string) => [...BASE_PATH, surveyId, 'cockpit'],
    RELATIVE: (surveyId: string) => [surveyId, 'cockpit'],
    PATH_KEYS: {
      SURVEY_ID: 'survey-id'
    }
  },
  SURVEYS_DETAIL_RUN_OVERVIEW: {
    ABSOLUTE: (surveyId: string) => [...BASE_PATH, surveyId, 'runs'],
    RELATIVE: (surveyId: string) => [surveyId, 'runs'],
    PATH_KEYS: {
      SURVEY_ID: 'survey-id',
      RUN_ID: 'run-id'
    }
  },
  SURVEYS_RUN_DETAIL: {
    ABSOLUTE: (surveyId: string, runId: string) => [...BASE_PATH, surveyId, 'runs', runId],
    RELATIVE: (surveyId: string, runId: string) => [surveyId, 'runs', runId],
    PATH_KEYS: {
      SURVEY_ID: 'survey-id',
      RUN_ID: 'run-id'
    }
  }
};
