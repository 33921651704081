export function isPresent(obj: any): boolean {
  return obj !== undefined && obj !== null;
}

export function stringifyOrUndefined(obj: any): string | undefined {
  return obj ? String(obj) : undefined;
}

export const exportCsvDateFormat = 'YYYY-MM-DD HH:mm:ss';

export const DASHBOARD_POLLING_INTERVAL_MS = 10000;
