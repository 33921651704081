import { SurveyAndonInfoDTO } from '../../swagger-generated-client-api';

export type AndonState = 'UP' | 'DOWN' | 'NA' | 'YELLOW';
export const andonStatus = {
  UP: 'UP' as AndonState,
  DOWN: 'DOWN' as AndonState,
  NA: 'NA' as AndonState,
  YELLOW: 'YELLOW' as AndonState
};

export class AndonInfo {
  protected constructor(
    public andonState: AndonState | undefined,
    public cyclePredictionEnabled: boolean | undefined,
    public preconditionsMet: boolean | undefined,
    public timestamp: Date | undefined
  ) {}

  public static fromDTO(dto: SurveyAndonInfoDTO): AndonInfo {
    return new AndonInfo(dto.andonState, dto.cyclePredictionEnabled, dto.preconditionsMet, dto.timestamp);
  }

  public toDTO(): SurveyAndonInfoDTO {
    return {
      andonState: this.andonState,
      cyclePredictionEnabled: this.cyclePredictionEnabled,
      preconditionsMet: this.preconditionsMet,
      timestamp: this.timestamp
    };
  }
}
