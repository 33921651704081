import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { APP_ROUTES } from '../../app-routing.config';
import { AuthenticationTokenStoreService } from '../services/store-services/authentication-token.store.service';

@Injectable({ providedIn: 'root' })
export class HasScopeGuard implements CanLoad, CanActivate {
  constructor(public authenticationTokenStoreService: AuthenticationTokenStoreService, public router: Router) {}

  // CanLoad
  // Sometimes, for security reasons, we do not want the user to be able to even see the source code
  // of the lazily loaded bundle if she does not have the right permissions. That’s what the canLoad guard is for.
  // If a canLoad guard returns false, the router will not load the bundle.
  public canLoad(route: Route): Observable<boolean> {
    return this.canAccess$(route);
  }

  // CanActivate
  // The canActivate guard is the default mechanism of adding permissions to the application.
  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.canAccess$(route);
  }

  private canAccess$(route: Route | ActivatedRouteSnapshot): Observable<boolean> {
    // Observable has to complete, therefore take(1)
    return this.authenticationTokenStoreService.getAuthenticationToken$().pipe(
      take(1),
      map(
        authenticationToken =>
          authenticationToken && route && route.data && authenticationToken.hasScope(route.data.scopes)
      ),
      map(hasNeededScope => {
        if (hasNeededScope) {
          return true;
        } else {
          this.router.navigate(APP_ROUTES.START.ABSOLUTE);
          return false;
        }
      })
    );
  }
}
