import { DASHBOARD_ROUTES } from '../dashboard/dashboard-routing.config';

const BASE_PATH = DASHBOARD_ROUTES.GATEWAYS.ABSOLUTE;

export const GATEWAY_ROUTES = {
  ROOT: {
    ABSOLUTE: [...BASE_PATH],
    RELATIVE: []
  },
  GATEWAYS_OVERVIEW: {
    ABSOLUTE: [...BASE_PATH],
    RELATIVE: []
  },
  GATEWAYS_REGISTER: {
    ABSOLUTE: [...BASE_PATH, 'register'],
    RELATIVE: ['register']
  },
  GATEWAY_BEACONS: {
    ABSOLUTE: (gatewayId: string) => [...BASE_PATH, gatewayId, 'beacons'],
    RELATIVE: (gatewayId: string) => [gatewayId, 'beacons'],
    PATH_KEYS: {
      GATEWAY_ID: 'gateway-id'
    }
  },
  GATEWAY_EDIT: {
    ABSOLUTE: (gatewayId: string) => [...BASE_PATH, gatewayId, 'edit'],
    RELATIVE: (gatewayId: string) => [gatewayId, 'edit'],
    PATH_KEYS: {
      GATEWAY_ID: 'gateway-id'
    }
  },
  GATEWAY_DELETE: {
    ABSOLUTE: (gatewayId: string) => [...BASE_PATH, gatewayId, 'delete'],
    RELATIVE: (gatewayId: string) => [gatewayId, 'delete'],
    PATH_KEYS: {
      GATEWAY_ID: 'gateway-id'
    }
  }
};
