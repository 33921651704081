import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { APP_ROUTES } from '../../app-routing.config';
import { AuthenticationService } from '../services/core-services/authentication.service';

@Injectable({ providedIn: 'root' })
export class IsSignedInGuard implements CanLoad, CanActivate {
  constructor(public authenticationService: AuthenticationService, public router: Router) {}

  // CanLoad
  // Sometimes, for security reasons, we do not want the user to be able to even see the source code
  // of the lazily loaded bundle if she does not have the right permissions. That’s what the canLoad guard is for.
  // If a canLoad guard returns false, the router will not load the bundle.
  public canLoad(): Observable<boolean> {
    return this.canAccess$();
  }

  // CanActivate
  // The canActivate guard is the default mechanism of adding permissions to the application.
  public canActivate(): Observable<boolean> {
    return this.canAccess$();
  }

  private canAccess$(): Observable<boolean> {
    // Observable has to complete, therefore take(1)
    return this.authenticationService.isSignedIn$.pipe(
      take(1),
      tap(isSignedIn => {
        if (!isSignedIn) {
          this.router.navigate(APP_ROUTES.START.ABSOLUTE);
        }
      })
    );
  }
}
